import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import IntroTitle from "../../../components/wcc/IntroTitle"
import AppSelector from "../../../components/wcc/AppSelector"
import WCCIntro from "../../../components/wcc/WCCIntro"
import AppStoreLinks from "../../../components/AppStoreLinks"

const Dictionary = () => {

  return (
    <Layout bgClass="bg-black" fgMode="light">
      <Seo 
        title="Dictionaries"
        description="An engine that blends tradition and technology"
      />
      <IntroTitle />
      <AppSelector />
      <div className="w-full pt-8 pb-16 text-white bg-black">
        <div className="grid max-w-screen-lg grid-cols-[1fr,1.2fr,1fr] gap-4 mx-auto">
        <div className="grid pt-16 pr-6">
            <section>
              <h2 className="mb-4 text-2xl font-bold">Every which way</h2>
              <p>
                The dictionary experience presents the content grouped by letters
                or organised by categories, to help you discover the language in multiple ways.
              </p>
            </section>
            <section>
              <h2 className="mb-4 text-2xl font-bold">Put your mark</h2>
              <p>
                As you discover these languages, you can add them to your personal dictionary
                by marking words as favourites.
              </p>
            </section>
            <section>
              <h2 className="mb-4 text-2xl font-bold">Maintaining tradition</h2>
              <p>
                All of these languages are traditionally spoken, not written. 
                The traditional view presents images allowing the user to discover
                languages the traditionally.
              </p>
            </section>
          </div>
        <div className="flex items-center justify-center">
            <StaticImage
              height={730}
              width={363}
              alt="Sticker Christmas"
              src="../../../images/iphone-wcc-dictionary.png" 
              placeholder="blurred"/>
          </div>
          <div className="grid pt-16 pr-6">
            <section>
              <h2 className="mb-4 text-2xl font-bold">DictionaryKit</h2>
              <p>
                We built the engine as a pluggable library allowing the language
                program to quickly bootstrap new apps, an not have to reinvent the
                technology.
              </p>
            </section>
            <section>
              <h2 className="mb-4 text-2xl font-bold">Media Player</h2>
              <p>
                Each dictionary integrates into the media player. Control
                the app straight from the lock screen using the system native controls.
              </p>
            </section>
            <section>
              <h2 className="mb-4 text-2xl font-bold">Widgets</h2>
              <p>
                Each dictionary has a word of the day widget that you can place on your
                home screen.
              </p>
            </section>
          </div>
        </div>
      </div>
      <AppStoreLinks 
        iOSStoreLink="https://bit.ly/3Hp3MPu"
        googlePlayStoreLink="https://bit.ly/3Htx27y"/>

      <WCCIntro/>
    </Layout>
 );
};

export default Dictionary
